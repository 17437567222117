:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._gap-t-space-10{gap:var(--t-space-10);}
:root ._jc-space-aroun100{justify-content:space-around;}
:root ._ai-center{align-items:center;}
:root ._pr-t-space-6{padding-right:var(--t-space-6);}
:root ._pl-t-space-6{padding-left:var(--t-space-6);}
:root ._pt-t-space-20{padding-top:var(--t-space-20);}
:root ._fg-1{flex-grow:1;}
:root ._ai-stretch{align-items:stretch;}
:root ._mt-60px{margin-top:60px;}
:root ._gap-30px{gap:30px;}