:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._bg-c-white{background-color:var(--c-white);}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btc-borderColor{border-top-color:var(--borderColor);}
:root ._brc-borderColor{border-right-color:var(--borderColor);}
:root ._bbc-borderColor{border-bottom-color:var(--borderColor);}
:root ._blc-borderColor{border-left-color:var(--borderColor);}
:root ._btlr-8px{border-top-left-radius:8px;}
:root ._btrr-8px{border-top-right-radius:8px;}
:root ._bbrr-8px{border-bottom-right-radius:8px;}
:root ._bblr-8px{border-bottom-left-radius:8px;}
:root ._pt-16px{padding-top:16px;}
:root ._pr-16px{padding-right:16px;}
:root ._pb-16px{padding-bottom:16px;}
:root ._pl-16px{padding-left:16px;}
:root ._mb-20px{margin-bottom:20px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._bxsh-0px2px5pxva26674076{box-shadow:0px 2px 5px var(--shadowColor);}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._fd-row{flex-direction:row;}
:root ._gap-t-space-3{gap:var(--t-space-3);}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._gap-t-space-2{gap:var(--t-space-2);}
:root ._pt-t-space-3{padding-top:var(--t-space-3);}
:root ._pr-t-space-3{padding-right:var(--t-space-3);}
:root ._pb-t-space-3{padding-bottom:var(--t-space-3);}
:root ._pl-t-space-3{padding-left:var(--t-space-3);}
:root ._miw-120px{min-width:120px;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-5{font-weight:var(--f-weight-5);}
:root ._ls-f-letterSpa1360334201{letter-spacing:var(--f-letterSpacing-5);}
:root ._fos-f-size-5{font-size:var(--f-size-5);}
:root ._lh-f-lineHeigh112924{line-height:var(--f-lineHeight-5);}
:root ._col-color{color:var(--color);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._whiteSpace-normal{white-space:normal;}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-0px{border-right-width:0px;}
:root ._blw-0px{border-left-width:0px;}
:root ._h-0px{height:0px;}
:root ._mah-0px{max-height:0px;}
:root ._transform-translateY-1736186894{transform:translateY(-0.5px);}
:root ._jc-flex-end{justify-content:flex-end;}
:root ._mt-t-space-3{margin-top:var(--t-space-3);}
:root ._bg-lighter{background-color:var(--lighter);}
:root ._btlr-t-radius-4{border-top-left-radius:var(--t-radius-4);}
:root ._btrr-t-radius-4{border-top-right-radius:var(--t-radius-4);}
:root ._bbrr-t-radius-4{border-bottom-right-radius:var(--t-radius-4);}
:root ._bblr-t-radius-4{border-bottom-left-radius:var(--t-radius-4);}
:root ._w-10037{width:100%;}
:root ._mih-200px{min-height:200px;}
@media (min-width: 661px){:root:root:root:root:root:root:root:root:root:root ._mih-_gtXs_500px{min-height:500px;}}
:root ._pb-t-space-1{padding-bottom:var(--t-space-1);}