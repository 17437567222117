:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._h-1px{height:1px;}
:root ._mb-30px{margin-bottom:30px;}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._gap-t-space-3{gap:var(--t-space-3);}
:root ._fd-row{flex-direction:row;}
:root ._w-10037{width:100%;}
:root ._h-200px{height:200px;}
:root ._h-400px{height:400px;}
:root ._mt-t-space-3{margin-top:var(--t-space-3);}
:root ._mb-t-space-6{margin-bottom:var(--t-space-6);}